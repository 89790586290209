import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import * as navStyle from "./nav.module.css"

const Nav = () => (
    <StaticQuery
    query={graphql`
        query{
            strapiGlobal{
                siteName
                Tel
            }
            allStrapiBlock{
                edges{
                    node{
                       title
                       slug
                    }
                }
            }
    }
    `}
    render={(data) => (
        <nav className={`${navStyle.nav} navbar`} role="navigation" aria-label="main navigation">
            <div className=" navbar-item">
                <Link className={`${navStyle.yellow__background}`} to='/'>{data.strapiGlobal.siteName}</Link>
            </div>
            <div className={`${navStyle.navbar__custom} navbar-menu`} id="navbarBasicExample">
              
                <div className={`${navStyle.wrapper}`}>
                    {data.allStrapiBlock.edges.map((name,i) => (
                        <Link className={`${navStyle.list__element} navbar-item`} key={`category__${name.node.title}`} to={`/#${name.node.slug}`}>
                            {name.node.title}
                        </Link>
                        
                    ))}
                   
                </div>
              
            </div>
            <p className={`${navStyle.yellow__background} navbar-end navbar-item`}>{data.strapiGlobal.Tel}</p>

            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
                    
            
        </nav>
    )}
    />
);
export default Nav;
