import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Seo from "./seo";
import Nav from "./nav";
import "./styles/global.css"


const Layout = ({ children, seo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          defaultSeo {
            metaTitle
            metaDescription
            shareImage {
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Seo seo={seo} />
        <section className="container"><Nav /></section>
        <main className="container is-fluid p-0">
        {children}
        </main>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;